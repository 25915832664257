import { template as template_e7a9d69e7bb4473188ed26132a1c1561 } from "@ember/template-compiler";
import i18n from "discourse-common/helpers/i18n";
const GeneratedInviteLink = template_e7a9d69e7bb4473188ed26132a1c1561(`
  <div>
    <p>{{i18n "user.invited.link_generated"}}</p>
    <p>
      <input
        value={{@link}}
        class="invite-link-input"
        type="text"
        autofocus="autofocus"
      />
    </p>
    {{#if @email}}
      <p>{{i18n "user.invited.valid_for" email=@email}}</p>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GeneratedInviteLink;
